// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.

// The list of file replacements can be found in `angular.json`.
import {EnvironmentBase} from './environment.base';
import {API_ENVIRONMENT_TYPES, API_URLS, APP_ENVIRONMENT_TYPES, APP_URLS, getUrl} from './urls';

/**
 * Changer cette valeur pour changer l'url du back.
 * - En development, les appels API sont redirigés vers l'unique serveur de développement.
 * - En preprod, les appels API sont redirigés vers le serveur de préproduction (spécifique à chaque instance) ou vers le serveur de développement si l'instance n'a pas de serveur de préproduction indiqué.
 * - En production, les appels API sont redirigés vers le serveur de développement pour des raisons de sécurité.
 */
const API_ENVIRONMENT: API_ENVIRONMENT_TYPES = API_ENVIRONMENT_TYPES.preprod;

/**
 * Changer cette valeur pour changer l'url de l'application mobile.
 * - En local, l'application mobile est définie vers une adresse web locale (exemple : http://localhost:4300/).
 * - En development, l'application mobile est définie vers l'adresse du serveur de développement.
 * - En preprod, l'application mobile est définie vers l'adresse du serveur de préproduction (spécifique à chaque instance) ou vers l'adresse du serveur de développement si l'instance n'a pas de serveur de préproduction indiqué.
 * - En production, l'application mobile est définie vers l'adresse du serveur de développement pour des raisons de sécurité.
 */
const APP_ENVIRONMENT: APP_ENVIRONMENT_TYPES = APP_ENVIRONMENT_TYPES.development;


export function buildApiUrl(brand?: string): string {
    return getUrl(API_URLS, brand, API_ENVIRONMENT);
}

export function buildUrl(brand?: string): string {
    return 'https://preprod.lms.tralalere.com/' + (brand ? brand + '/' : '');
}

export function buildNodeUrl(brand?: string): string {
    return 'https://preprod.node.lms.tralalere.com/';
}

export function lrsAuthToken(): string {
    return `ZGRmMjU3NWQ3ZDE0ZjliNjg3M2M2OTMyOWFmOTc2ODRiNGMwM2JjNDo0MDQ2MDE0MWFhODU0YmZkZDA0N2VlNDExMzNlZmQxOTRlYTRjZGQ2`;
}

export const environment: EnvironmentBase = {
    production: false,
    hmr: false,
    baseApiUrl: buildApiUrl,
    baseNodeUrl: buildNodeUrl,
    baseUrl: buildUrl,
    baseAppUrl: (brand) => getUrl(APP_URLS, brand, APP_ENVIRONMENT),
    mindmapUrl: (brand) => buildApiUrl(brand) + '/mindmap/',
    gamecodeUrl: (brand) => buildApiUrl(brand) + '/gamecode/index.html',
    gleamcodeUrl: (brand) => buildApiUrl(brand) + '/gleamcode/index.html',
    myCityUrl: (brand) => buildApiUrl(brand) + '/story/AFE_City/',
    basePlatformPath: '',
} as const;

export function getTrackingID(trackingId?: string): string {
    return 'G-WTQWL768HY'; // LMS Site vitrine dev tracking ID
}

export function getTrackingIDMatomo(trackingId?: string): string {
    return '3'; // default
}

export function getTrackingIDGAds(trackingId?: string): string {
    return ''; // default
}

export function getTrackingIDGTM(trackingId?: string): string {
    return ''; // default
}

export function getTrackingIDMetaPixel(trackingId?: string): string {
    return ''; // default
}

export function getTrackingIDSnapchat(trackingId?: string): string {
    return ''; // default
}

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
